// SPDX-FileCopyrightText: 2020 Tocqueville Group
//
// SPDX-License-Identifier: AGPL-3.0-or-later

import "./style/index.css";
import "./style/syntax-highlighting.css";
import "./style/markdown.css";
import App from "./components/app.tsx";

export default App;
