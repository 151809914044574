// SPDX-FileCopyrightText: 2020 Tocqueville Group
//
// SPDX-License-Identifier: AGPL-3.0-or-later
import {
    ProposalId,
    DraftTzip,
    Tzip,
    TzipBubble,
    TzipBubbles,
    DraftTzipBubbles,
    DraftTzipBubble,
    TzipIndex,
    GeneratedIndexData
} from "../common";
import axios from "axios";

export function buildTzips(
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    generatedIndexData: GeneratedIndexData
): TzipIndex {
    const bubbles: TzipBubbles = new Map();
    const draftBubbles: DraftTzipBubbles = new Map();
    const bubbleData = generatedIndexData["bubbles"];
    const draftBubbleData = generatedIndexData["draft-bubbles"];
    for (const k in bubbleData) {
        bubbles.set(k, new TzipBubble(bubbleData[k]));
    }

    for (const k in draftBubbleData) {
        draftBubbles.set(k, new DraftTzipBubble(draftBubbleData[k]));
    }
    return new TzipIndex(bubbles, draftBubbles, generatedIndexData["repo-url"]);
}

export function getTzips(publicPath: string): Promise<TzipIndex> {
    return axios.get(`${publicPath}proposals.json`).then(response => {
        return buildTzips(response.data);
    });
}

export function getTzip(
    proposalId: ProposalId,
    publicPath: string
): Promise<Tzip> {
    return axios
        .get(`${publicPath}proposal/${proposalId}.json`)
        .then(response => {
            return new Tzip(response.data);
        });
}

export function getTzipDraft(
    title: string,
    publicPath: string
): Promise<DraftTzip> {
    return axios.get(`${publicPath}proposal/${title}.json`).then(response => {
        return new DraftTzip(response.data);
    });
}
