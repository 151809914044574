// SPDX-FileCopyrightText: 2021 Tezos Commons
// SPDX-License-Identifier: AGPL-3.0-or-later
export type ProposalId = string;

export type DraftTitle = string;
export type TzipBubbles = Map<ProposalId, TzipBubble>;
export type DraftTzipBubbles = Map<DraftTitle, DraftTzipBubble>;

export class GeneratedIndexData {
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    bubbles: any;
    "draft-bubbles": any;
    "repo-url": string;
}

export class TzipIndex {
    bubbles!: TzipBubbles;
    draftBubbles!: DraftTzipBubbles;
    repoUrl!: string;

    constructor(
        bubbles: TzipBubbles,
        draftBubbles: DraftTzipBubbles,
        repoUrl: string
    ) {
        this.bubbles = bubbles;
        this.draftBubbles = draftBubbles;
        this.repoUrl = repoUrl;
    }
}

// This is a type to save ourselves from interpolating unsafe html into
// html elements. The way this is suppoed to be used is to recieve html
// that is guaranteed to be sanitized from the backend code. Thus this
// constructor requires html code wrapped in the special `__html` key.
export class SafeHtml {
    readonly __html!: string;

    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    constructor(obj: Record<string, any>) {
        if ("__html" in obj) {
            this.__html = obj["__html"];
        } else {
            throw "Safe html object should not be initialized with anything\
            other then sanitized html from the backed wrapped in the expected way";
        }

        return this;
    }
}

export class TzipMeta {
    title!: SafeHtml | undefined;
    status!: string | undefined;
    created!: string | undefined;
    type!: string | undefined;
    author!: string | undefined;
    discourseTopicId!: number | undefined;

    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    constructor(obj: Record<string, any>) {
        if ("title" in obj) {
            this.title = obj["title"];
        }

        if ("status" in obj) {
            this.status = obj["status"];
        }

        if ("type" in obj) {
            this.type = obj["type"];
        }

        if ("author" in obj) {
            this.author = obj["author"];
        }

        if ("created" in obj) {
            this.created = obj["created"];
        }

        if ("discourse-topic-id" in obj) {
            this.discourseTopicId = obj["discourse-topic-id"];
        }

        return this;
    }
}

export class DraftTzipMeta {
    title!: SafeHtml | undefined;
    created!: string | undefined;
    date!: string | undefined;
    version!: string | undefined;
    author!: string | undefined;

    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    constructor(obj: Record<string, any>) {
        if ("title" in obj) {
            this.title = obj["title"];
        }

        if ("author" in obj) {
            this.author = obj["author"];
        }

        if ("created" in obj) {
            this.created = obj["created"];
        }

        if ("date" in obj) {
            this.date = obj["date"];
        }

        return this;
    }
}

export class TzipBubble {
    meta!: TzipMeta;
    summary!: SafeHtml;
    directLink!: string;

    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    constructor(obj: Record<string, any>) {
        if ("summary" in obj) {
            this.summary = new SafeHtml(obj["summary"]);
        }

        if ("meta" in obj) {
            this.meta = new TzipMeta(obj["meta"]);
        }

        if ("direct-link" in obj) {
            this.directLink = obj["direct-link"];
        }

        return this;
    }
}

export class DraftTzipBubble {
    meta!: DraftTzipMeta;
    summary!: SafeHtml;
    directLink!: string;

    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    constructor(obj: Record<string, any>) {
        if ("summary" in obj) {
            this.summary = new SafeHtml(obj["summary"]);
        }

        if ("meta" in obj) {
            this.meta = new DraftTzipMeta(obj["meta"]);
        }

        if ("direct-link" in obj) {
            this.directLink = obj["direct-link"];
        }

        return this;
    }
}

export class Tzip {
    meta!: TzipMeta;
    proposalId!: ProposalId;
    source!: string;
    rendered!: SafeHtml;
    directLink!: string;

    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    constructor(obj: Record<string, any>) {
        if ("meta" in obj) {
            this.meta = new TzipMeta(obj["meta"]);
        }

        if ("proposal-id" in obj) {
            this.proposalId = obj["proposal-id"];
        }

        if ("source" in obj) {
            this.source = obj["source"];
        }

        if ("rendered" in obj) {
            this.rendered = new SafeHtml(obj["rendered"]);
        }

        if ("direct-link" in obj) {
            this.directLink = obj["direct-link"];
        }

        return this;
    }
}

export class DraftTzip {
    meta!: DraftTzipMeta;
    source!: string;
    rendered!: SafeHtml;
    directLink!: string;

    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    constructor(obj: Record<string, any>) {
        if ("meta" in obj) {
            this.meta = new DraftTzipMeta(obj["meta"]);
        }

        if ("source" in obj) {
            this.source = obj["source"];
        }

        if ("rendered" in obj) {
            this.rendered = new SafeHtml(obj["rendered"]);
        }

        if ("direct-link" in obj) {
            this.directLink = obj["direct-link"];
        }

        return this;
    }
}

export interface SelectOption<T> {
    label: string;
    value: T;
}

export type FilterAttribute = "Type" | "Status" | "Author";

export type SortAttribute = "Date" | "Proposal ID" | "Title";

type FilterOptions = Map<FilterAttribute, Array<string>>;

// A function that is used to fill search keys in a FilterOptions map from values from a
// `URLSearchParams` object. The field `key` is used to obtain values from the URLSearchParam.
function buildFilterQuery(
    qsp: URLSearchParams,
    key: FilterAttribute,
    fo: FilterOptions
): void {
    fo.set(key, qsp.getAll(key));
}

// Check if `n` is included in any of `h`. If `h` is empty, then
// return found. If `n` is undefined, return not found. Or else return
// found if `n` is present in `h`.
function checkInclusion(n: string | undefined, h: Array<string>): boolean {
    if (h.length > 0) {
        if (n) {
            return h.includes(n);
        } else {
            return false;
        }
    } else return true;
}

function applyFilters(inp: TzipBubble, filters: FilterOptions): boolean {
    let r = true;

    filters.forEach((vals, key) => {
        switch (key) {
            case "Type":
                if (!checkInclusion(inp?.meta?.type, vals)) {
                    r = false;
                }
                break;
            case "Status":
                if (!checkInclusion(inp?.meta?.status, vals)) {
                    r = false;
                }
                break;
            case "Author":
                if (!checkInclusion(inp?.meta?.author, vals)) {
                    r = false;
                }
                break;
        }
    });

    return r;
}

// Function that compares strings using their natural ordering (using localCompare
// function) and other types using usual comparison operators. If both are undefined
// return 0 for now.
function compare(
    a: string | Date | ProposalId | undefined,
    b: string | Date | ProposalId | undefined
): number {
    if (a !== undefined && b !== undefined) {
        if (typeof a === "string" && typeof b === "string") {
            return a.localeCompare(b, undefined, {
                numeric: true,
                sensitivity: "base"
            });
        } else {
            if (a > b) {
                return 1;
            }

            if (a < b) {
                return -1;
            }
            return 0;
        }
    } else {
        return 0;
    }
}

function sortBy(
    tzipsIn: Array<[ProposalId, TzipBubble]>,
    sortBy: SortAttribute
): Array<[ProposalId, TzipBubble]> {
    switch (sortBy) {
        case "Date":
            return tzipsIn.sort(([, t1], [, t2]) =>
                compare(t1.meta.created, t2.meta.created)
            );
        case "Title":
            return tzipsIn.sort(([, t1], [, t2]) =>
                compare(t1.meta.title?.__html, t2.meta.title?.__html)
            );
        case "Proposal ID":
            return tzipsIn.sort(([p1], [p2]) => compare(p1, p2));
        default:
            return tzipsIn;
    }
}

// An object to wrap the filter/sort logic and query parsing and query
// generation.
export class FilterSortOptions {
    filterOptions: FilterOptions;
    sortAttribute: SortAttribute;
    showDrafts: boolean | undefined;

    constructor(
        fo: FilterOptions,
        so: SortAttribute,
        showDrafts: boolean | undefined
    ) {
        this.filterOptions = fo;
        this.sortAttribute = so;
        this.showDrafts = showDrafts;
    }

    setSort(so: SortAttribute): void {
        this.sortAttribute = so;
    }

    setFilter(fa: FilterAttribute, vals: Array<string>): void {
        this.filterOptions.set(fa, vals);
    }

    setShowDrafts(b: boolean): void {
        this.showDrafts = b;
    }

    getFilters(fa: FilterAttribute): Array<string> {
        const v = this.filterOptions.get(fa);
        if (v) {
            return v;
        } else {
            return [];
        }
    }

    static from(q: string): FilterSortOptions {
        const qsp = new URLSearchParams(q);

        const fo: FilterOptions = new Map();

        buildFilterQuery(qsp, "Type", fo);
        buildFilterQuery(qsp, "Status", fo);
        buildFilterQuery(qsp, "Author", fo);

        let so: SortAttribute;

        switch (qsp.get("sort")) {
            case "Date":
                so = "Date";
                break;
            case "Proposal ID":
                so = "Proposal ID";
                break;
            case "Title":
                so = "Title";
                break;
            default:
                so = "Date";
        }

        let showDrafts = undefined;

        if (qsp.get("showDrafts")) {
            showDrafts = true;
        }
        return new FilterSortOptions(fo, so, showDrafts);
    }

    toString(): string {
        const qsp = new URLSearchParams();

        this.filterOptions.forEach((vals, key) => {
            vals.forEach(val => {
                qsp.append(key, val);
            });
        });

        qsp.append("sort", this.sortAttribute);

        if (this.showDrafts) {
            qsp.append("showDrafts", "true");
        }

        return qsp.toString();
    }

    // Filter/Sort a bunch of TzipBubble according to this filter/sort params
    filterSort(inp: TzipBubbles): TzipBubbles {
        const items: Array<[ProposalId, TzipBubble]> = Array.from(
            inp.entries()
        );

        const filtered: Array<[ProposalId, TzipBubble]> = items.filter(
            ([, tb]) => {
                return applyFilters(tb, this.filterOptions);
            }
        );

        const sorted: Array<[ProposalId, TzipBubble]> = sortBy(
            filtered,
            this.sortAttribute
        );

        return new Map(sorted);
    }
}

export const defaultRepoUrl = "https://gitlab.com/tezos/tzip/-/blob/master";
