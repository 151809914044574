// SPDX-FileCopyrightText: 2020 Tocqueville Group
//
// SPDX-License-Identifier: AGPL-3.0-or-later

/** @jsx h */
import { FunctionalComponent, h } from "preact";
import { Router, RouterProps, route } from "preact-router";
import AsyncRoute from "preact-async-route";
import { Props as HomeProps } from "../routes/home";
import Home from "../routes/home";
import { Props as ProposalProps } from "../routes/proposal";
import Proposal from "../routes/proposal";
import DraftProposal from "../routes/draft";
import NotFound from "../routes/notfound";
import * as JSON from "../json";
import {
    DraftTzip,
    Tzip,
    TzipIndex,
    ProposalId,
    GeneratedIndexData
} from "../common";
import tezosLogo from "../../public/images/tezos-logo-alt.png";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
if ((module as any).hot) {
    // tslint:disable-next-line:no-var-requires
    require("preact/debug");
}

type ProvidedTzipData =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    { proposals: Record<string, any>; index: GeneratedIndexData } | undefined;

interface Props {
    path: string | undefined;
    tzipData: ProvidedTzipData; // eslint-disable-next-line @typescript-eslint/no-explicit-any
    publicPath: string | undefined;
}

/* eslint-disable */
function getTzip(pid: ProposalId, provided: ProvidedTzipData, publicPath: string): Promise<Tzip> {
    if (provided?.proposals[pid]) {
        return Promise.resolve(new Tzip(provided.proposals[pid]));
    }
    return JSON.getTzip(pid, publicPath);
}

function getTzipDraft(draft_title: string, provided: ProvidedTzipData, publicPath: string): Promise<DraftTzip> {
    if (provided?.proposals[draft_title]) {
        return Promise.resolve(new DraftTzip(provided.proposals[draft_title]));
    }
    return JSON.getTzipDraft(draft_title, publicPath);
}

function getTzips(provided: ProvidedTzipData, publicPath: string): Promise<TzipIndex> {
    if (provided?.index) {
        return Promise.resolve(JSON.buildTzips(provided.index));
    } else {
        if (typeof window == "undefined") {
            // So that we don't get an error during the build, prior to ssr.
            // We only need this in the requests originated by index route, since
            // only it is pre-rendered by default.
            return Promise.resolve(new TzipIndex(new Map(), new Map(), ""));
        } else {
            return JSON.getTzips(publicPath);
        }
    }
}
/* eslint-enable */

const App: FunctionalComponent<Props> = (props: Props) => {
    let publicPath: string;
    if (props.publicPath === undefined) {
        // eslint-disable-next-line @typescript-eslint/camelcase
        publicPath = __webpack_public_path__;
    } else {
        publicPath = props.publicPath;
    }

    let cachedFilterParams: undefined | string = undefined;
    /* eslint-disable */
    let getHome = function (url: string, cb: any): Promise<FunctionalComponent<HomeProps>> {

      return getTzips(props.tzipData, publicPath).then((tzips: TzipIndex) => {
        return ((routeProps) => {
          // If there is no filter params in url, check and use the cached ones.
          if (routeProps.q === undefined && cachedFilterParams !== undefined) {
            route(url + `q/${cachedFilterParams}`);
          }
          cachedFilterParams = routeProps.q;
          return (<Home publicPath={publicPath} q={routeProps.q} indexData={tzips}/>) })
        });
    }

    let getProposal = function (url: string, cb: any, routeProps: {proposalId: ProposalId}): Promise<FunctionalComponent<ProposalProps>> {

      // redirect to normalized path unless it contains a hash
      if (!url.endsWith("/") && (url.indexOf("#") === -1)) {
        route(url + "/");
      }

      return getTzip(routeProps.proposalId, props.tzipData, publicPath).then((tzip: Tzip) => {
        let discourseUrl : undefined | string = undefined;
        try {
          discourseUrl = process.env.discourseUrl;
        } catch {}

        return (() => (<Proposal publicPath={publicPath} tzip={tzip} discourseUrl={discourseUrl}/>))

      }).catch(() =>(() => <NotFound publicPath={publicPath}/>));
    }

    let getProposalDraft = function (url: string, cb: any, routeProps: {draftTitle: string}): Promise<FunctionalComponent<ProposalProps>> {

      // redirect to normalized path unless it contains a hash
      if (!url.endsWith("/") && (url.indexOf("#") === -1)) {
        route(url + "/");
      }

      return getTzipDraft(routeProps.draftTitle, props.tzipData, publicPath).then((tzip: DraftTzip) => {

        return (() => (<DraftProposal publicPath={publicPath} tzip={tzip}/>))

      }).catch(() =>(() => <NotFound publicPath={publicPath}/>));
    }
    /* eslint-enable */

    const handleRoute = (e: RouterProps): void => {
        // This hack is to handle cases where a user directly opens a fronend
        // routed page in the browser, without going through the index page.
        // __webpack_public_path__ will have the path upto the subfolder that
        // contains the app. The rest will be the actual route.
        //
        // eslint-disable-next-line @typescript-eslint/camelcase
        if (e.url) {
            if (e.url.startsWith(publicPath)) {
                if (e.url !== publicPath) {
                    // First go to the root path and only then to the actual path so that
                    // we don't endup with duplicate route segments. For example, if the user
                    // access the path '/custom/proposal/tzip-12/' directly, where `/custom/` is
                    // the subfolder here the app is hosted. So the frontend route is 'proposal/tzip-12/'.
                    // But if we navigate to that directly, the correct page will show up, but the url will
                    // be set to '/custom/proposal/proposal/tzip-12/'. To prevent this, we first route to
                    // the root path, and then to the actual frontend route.

                    const hash = decodeURI(location.hash.toString());
                    const url = e.url;
                    route(publicPath, true);
                    route(url, true);
                    if (hash.length > 0) {
                        location.hash = hash;
                        // A ugly hack where we try to navigate to the hash
                        // location, but the html fragment with it hasn't loaded
                        // yet. So we try to do that in this interval timer. We
                        // try 5 times with 100 ms intervals and give up.
                        let tries = 0;
                        const intervalId = setInterval(() => {
                            if (tries > 5) {
                                clearInterval(intervalId);
                            }
                            const element = document.getElementById(hash);
                            if (element !== null) {
                                const topPos =
                                    element.getBoundingClientRect().top +
                                    window.pageYOffset;

                                window.scrollTo({
                                    top: topPos // scroll so that the element is at the top of the view
                                });
                                clearInterval(intervalId);
                            } else {
                                tries = tries + 1;
                            }
                        }, 100);
                    }
                }
            }
        }
    };

    return (
        <div id="app">
            <div class="top-header">
                <div class="top-logo">
                    <a href="https://www.tezosagora.org">
                        <img src={tezosLogo} />
                        <span class="top-logo-text">AGORA</span>
                    </a>
                </div>
                <div class="top-header-links">
                    <a href="https://wiki.tezosagora.org/">Wiki</a>
                    <a href="https://tezos.com/learn/getting-started/">Get Started</a>
                    <a href="https://www.tezosagora.org/learn">Learn</a>
                </div>
            </div>
            <Router url={props.path} onChange={handleRoute}>
                <AsyncRoute
                    path={publicPath + "proposal/:proposalId/"}
                    getComponent={getProposal}
                />
                <AsyncRoute
                    path={publicPath + "draft/:draftTitle/"}
                    getComponent={getProposalDraft}
                />
                <AsyncRoute
                    path={publicPath + "q/:q?"}
                    getComponent={getHome}
                />
                <AsyncRoute path={publicPath} getComponent={getHome} />
                <NotFound publicPath={publicPath} default />
            </Router>
        </div>
    );
};

export default App;
